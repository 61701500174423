<template>
	<div class="container flex f-column f-align" @click="$refs.inputRef.focus()">
		<span class="font40 black bold">请扫描衣扣条码</span>
		<img class="mt50" src="../assets/image/qt-xz-sm@2x.png" style="width: 19.9375rem;height: 16.8125rem;">
		<span class="font30 gray mt50">条码扫描不上？</span>
		<div class="btn" @click.stop="$router.push('./quCode?type=xie&id=' + $route.query.id)">手动输入</div>
		<input ref="inputRef" class="code-input" v-model="str" />
	</div>
</template>
<script>
	export default {
		data() {
			return {
				str: '',
				type: ''
			};
		},
		mounted() {
			this.type = localStorage.getItem('type')
			if (this.type == 'xy-cun') {
				this.$emit('change', '/adminList')
			} else {
				this.$emit('change', '/orderList')
			}
			this.$refs.inputRef.focus()
			this.onkeyDownHandle()
		},
		methods: {
			onkeyDownHandle() {
				let _this = this
				document.onkeydown = function(e) {
					if (_this.$route.path == '/shoesCode') {
						let key = e.keyCode
						if (key == 13) {
							if (_this.str == '') return;
							if (_this.type == 'xy-qu') {
								_this.$post('/Order/adminGetOut', {
									shoeBuckleNumber: _this.str,
									orderDetailId: _this.$route.query.id,
									adminId: localStorage.getItem('adminId')
								}).then((res) => {
									_this.str = ''
									_this.$toast({
										message: res.msg,
										duration: 3000
									})
									if (res.code == 200) {
										setTimeout(() => {
											_this.$router.go(-1)
										}, 500)
									}
								})
							} else {
								_this.$post('/Order/adminDeposit', {
									shoeBuckleNumber: _this.str,
									gzbh: localStorage.getItem('gzbh'),
									adminId: localStorage.getItem('adminId')
								}).then((res) => {
									_this.str = ''
									_this.$toast({
										message: res.msg,
										duration: 3000
									})
									if (res.code == 200) {
										localStorage.setItem('xmbh', res.xmbh)
										localStorage.setItem('openCmd', res.openCmd)
										localStorage.setItem('portName', res.portName)
										_this.$router.push('./boxDoorOpen')
									}
								})
							}
						}
					}
				}
			}
		},
		beforeDestroy() {
			document.onkeydown = undefined
		}
	};
</script>
<style scoped lang="scss">
	.container {
		padding-top: 50px;

		.code-input {
			position: absolute;
			top: 0;
			left: -1500px;
		}

		.btn {
			width: 250px;
			height: 80px;
			background: rgba(64, 130, 253, 1);
			border-radius: 6px;
			line-height: 80px;
			text-align: center;
			font-size: 30px;
			color: #ffffff;
			margin-top: 80px;
		}
	}
</style>
